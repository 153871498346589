var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"placeholder":"Search Merchant","outlined":"","dense":"","autocomplete":"off","clearable":"","color":"#F05326","prepend-inner-icon":"mdi-magnify","hide-details":true,"readonly":_vm.process.run},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getMerchant()},"click:clear":function($event){_vm.searching = ''}},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:" text-capitalize rounded-lg",attrs:{"depressed":"","height":"40","color":"primary","loading":_vm.process.run},on:{"click":_vm.showDialogAddMerchant}},[_vm._v(" Add Merchant ")])],1)],1),_c('div',{staticClass:"mt-3 mb-8"},[_c('v-skeleton-loader',{staticClass:"ma-auto px-0",attrs:{"loading":_vm.$store.state.process.run,"tile":false,"transition":"scale-transition","type":"table-tbody"}},[_c('v-data-table',{staticClass:"table-list pa-0",attrs:{"hide-default-footer":"","fixed-header":"","loading":_vm.$store.state.process.run || _vm.process.run,"headers":_vm.headers,"items":_vm.merchant_object.list,"items-per-page":_vm.merchant_object.limit,"page":_vm.merchant_object.page,"server-items-length":_vm.merchant_object.total,"item-key":"id","footer-props":{
          itemsPerPageText: 'Data per halaman',
          'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
        }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.merchant_object, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.merchant_object, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.merchant_object, "page", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
        var props = ref.props;
return [_c('Pagination',{attrs:{"model":props},on:{"reload":function($event){return _vm.getMerchant(_vm.page)},"changeLimit":function($event){_vm.limit = $event;
              _vm.getMerchant();},"changePage":function($event){_vm.page = $event;
              _vm.getMerchant($event);}}})]}},{key:"item",fn:function(props){return [_c('tr',{staticClass:"mx-3 px-3 box-shadow"},[_c('td',{staticClass:"text-start white"},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('td',{staticClass:"text-start white"},[_vm._v(" "+_vm._s(_vm._f("datetimeFull")(props.item.created_at))+" ")]),_c('td',{staticClass:"text-right white"},[_c('v-menu',{attrs:{"offset-y":"","nudge-left":"110"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical-circle-outline")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0 rounded-lg",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.showDialogAddMerchant(props.item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"size":"20","color":"error"}},[_vm._v("mdi-pencil-circle-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Update Merchant")])],1)],1)],1)],1)],1)])]}}])})],1)],1),_c('DialogAddMerchant',{ref:"dialog_add_merchant",attrs:{"company_id":_vm.company_id},on:{"action":_vm.getMerchant}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }