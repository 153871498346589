<template>
  <div>
    <v-row justify="space-between" class="mt-3">
      <v-col cols="4">
        <v-text-field
          placeholder="Search Merchant"
          outlined
          dense
          autocomplete="off"
          clearable
          color="#F05326"
          class="rounded-lg"
          prepend-inner-icon="mdi-magnify"
          :hide-details="true"
          :readonly="process.run"
          v-model="searching"
          v-on:keyup.enter="getMerchant()"
          @click:clear="searching = ''"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn
          depressed
          height="40"
          color="primary"
          class=" text-capitalize rounded-lg"
          :loading="process.run"
          @click="showDialogAddMerchant"
        >
          Add Merchant
        </v-btn>
      </v-col>
    </v-row>
    <div class="mt-3 mb-8">
      <v-skeleton-loader
        class="ma-auto px-0"
        :loading="$store.state.process.run"
        :tile="false"
        transition="scale-transition"
        type="table-tbody"
      >
        <v-data-table
          class="table-list pa-0"
          hide-default-footer
          fixed-header
          :loading="$store.state.process.run || process.run"
          :headers="headers"
          :items="merchant_object.list"
          :items-per-page.sync="merchant_object.limit"
          :page.sync="merchant_object.page"
          :server-items-length="merchant_object.total"
          item-key="id"
          :footer-props="{
            itemsPerPageText: 'Data per halaman',
            'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
          }"
        >
          <template slot="footer" slot-scope="{ props }">
            <Pagination
              @reload="getMerchant(page)"
              @changeLimit="
                limit = $event;
                getMerchant();
              "
              @changePage="
                page = $event;
                getMerchant($event);
              "
              :model="props"
            />
          </template>
          <template slot="item" slot-scope="props">
            <tr class="mx-3 px-3 box-shadow">
              <td class="text-start white">
                {{ props.item.name }}
              </td>
              <td class="text-start white">
                {{ props.item.created_at | datetimeFull }}
              </td>
              <td class="text-right white">
                <v-menu offset-y nudge-left="110">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="pa-0 rounded-lg">
                    <v-list-item @click.stop="showDialogAddMerchant(props.item.id)">
                      <v-list-item-icon class="mr-4">
                        <v-icon size="20" color="error">mdi-pencil-circle-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Update Merchant</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </div>
    <DialogAddMerchant ref="dialog_add_merchant" :company_id="company_id" @action="getMerchant"></DialogAddMerchant>
  </div>
</template>

<script>
import { get } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import DialogAddMerchant from "./components/DialogAddMerchant.vue";

export default {
  components: {
    Pagination,
    DialogAddMerchant,
  },
  data() {
    return {
      merchant_object: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      searching: '',
      process: {
        run: false,
      },
      headers: [
        { text: "Full Name", align: "start", value: "fullname", sortable: false },
        { text: "Date Created", align: "start", value: "created_at", sortable: false },
        { text: "Action", align: "end", value: "status", sortable: false },
      ],
    }
  },
  props: {
    company_id: String,
  },
  watch: {
    "selected.pagination": function(val) {
      this.getMerchant(val + 1);
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.getMerchant();
    }, 500),
  },
  methods: {
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    async getMerchant(paging) {
      this.process.run = true;
      await get(`merchant/v1/superuser/list`, {
        params: {
          company_id: this.company_id,
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          q: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        this.merchant_object = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
      });
    },
    showDialogAddMerchant(id) {
      this.$refs.dialog_add_merchant.showDialog(id);
    },
  },
}
</script>
