<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="400">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text">
            Ganti Password User
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="hideDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="formChangePassword">
            <v-row>
              <v-col cols="12" class="pb-1">
                <div>Ganti Password User <b>{{ fullname }}</b></div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Current Password"
                  outlined
                  dense
                  color="primary"
                  autocomplete="off"
                  tabindex="1"
                  hide-details="auto"
                  :type="show_password_one ? 'text' : 'password'"
                  :append-icon="show_password_one ? 'mdi-eye-off' : 'mdi-eye'"
                  :rules="rules.password"
                  :error-messages="error_message.current_password ? error_message.current_password : ''"
                  v-model="form.current_password"
                  @click:append="show_password_one = !show_password_one">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="New Password"
                  outlined
                  dense
                  color="primary"
                  autocomplete="off"
                  tabindex="2"
                  hide-details="auto"
                  :type="show_password_two ? 'text' : 'password'"
                  :append-icon="show_password_two ? 'mdi-eye-off' : 'mdi-eye'"
                  :rules="rules.password"
                  :error-messages="error_message.password ? error_message.password : ''"
                  v-model="form.password"
                  @click:append="show_password_two = !show_password_two">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Confirm New Password"
                  outlined
                  dense
                  color="primary"
                  autocomplete="off"
                  tabindex="3"
                  hide-details="auto"
                  :type="show_password_three ? 'text' : 'password'"
                  :append-icon="show_password_three ? 'mdi-eye-off' : 'mdi-eye'"
                  :rules="rules.password"
                  :error-messages="error_message.confirm_password ? error_message.confirm_password : '' || check_confirm_password"
                  v-model="form.confirm_password"
                  @click:append="show_password_three = !show_password_three"
                  @change="checkConfirmPassword">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-2"
            class="text-capitalize mr-1"
            depressed
            :loading="btn_loading"
            @click="hideDialog">
            Cancel
          </v-btn>
          <v-btn 
            color="primary"
            class="white--text text-capitalize"
            depressed
            :loading="btn_loading"
            @click="changePassword">
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"/>
  </div>
</template>

<script>
import { put } from '@/service/Axios'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
	data () {
		return {
			show_dialog: false,
      show_password_one: false,
      show_password_two: false,
      show_password_three: false,
			btn_loading: false,
      rules: {
				password: [
				  v => !!v || 'This field is required',
				  v => ( v && v.length >= 7 ) || "Password can't less than 7 characters",
				],
			},
      user_id: '',
      fullname: '',
      form: {
        current_password: '',
        password: '',
        confirm_password: '',
      },
      error_message: {},
      check_confirm_password: ''
		}
	},
	computed: {
	},
	methods: {
    showDialog(name, id) {
      this.user_id = id
      this.fullname = name
      this.show_dialog = true
    },
		hideDialog() {
			this.show_dialog = false
			this.btn_loading = false
      this.$refs.formChangePassword.reset()
      this.$refs.formChangePassword.resetValidation()
      this.error_message = {}
		},
    checkConfirmPassword() {
      if (this.form.confirm_password != this.form.password) {
        this.check_confirm_password = "Confirm New Password different with New Password"
      } else {
        this.check_confirm_password = ""
      }
    },
    async changePassword() {
      if (this.$refs.formChangePassword.validate()) {
        this.btn_loading = true
        await put(`users/v1/superuser/company_user/change_password/${this.user_id}`, {
          data: {
            current_password: this.form.current_password,
            password: this.form.password,
            confirm_password: this.form.confirm_password,
          }
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.hideDialog()
            this.$refs.snackbar.open("#4CAF50", `Change Password Successfully`);
          } else {
            this.hideDialog()
            this.$refs.snackbar.open("#e53935", `Change Password Failed`);
          }
        }).catch(() => {
          this.error_message = this.$store.state.ContentValidation
          this.btn_loading = false
        })
      }
    },
	}
};
</script>