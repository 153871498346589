<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="400">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            {{ dialog_type }} Merchant
          </div>
          <v-spacer></v-spacer>
          <v-icon :disabled="btn_loading" color="white" @click="hideDialog">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="formAddMerchant">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Merchant Name"
                  outlined
                  dense
                  color="primary"
                  :rules="rules.input"
                  v-model="form.name">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-2"
            class="text-capitalize mr-1"
            depressed
            :loading="btn_loading"
            @click="hideDialog">
            Cancel
          </v-btn>
          <v-btn 
            color="primary"
            class="text-capitalize"
            depressed
            :loading="btn_loading"
            @click="saveMerchant"
          >
            {{ dialog_type }} Merchant
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"></Snackbar>
  </div>
</template>

<script>
import { get, post, put } from "@/service/Axios";
import Snackbar from '@/components/Snackbar.vue';

export default {
  components: { Snackbar },
  data() {
    return {
      show_dialog: false,
      dialog_type: 'add',
      btn_loading: false,
      rules: {
        input: [ v => !!v || 'This field is required' ],
			},
      form: {
        name: ''
      },
      merchant_id: '',
      error_message: {},
    }
  },
  props: {
    company_id: String,
  },
  methods: {
    async showDialog(id) {
      this.show_dialog = true
      if (id.length > 0) {
        this.merchant_id = id
        this.dialog_type = 'edit'
        this.btn_loading = true
        await get(`merchant/v1/superuser/detail/${ id }`)
        .then((response) => {
          let res = response.data
          this.form.name = res.data.name
          this.btn_loading = false
        })
      } else {
        this.dialog_type = 'add'
      }
		},
    hideDialog() {
			this.show_dialog = false
			this.btn_loading = false
      this.merchant_id = ''
      this.$refs.formAddMerchant.reset()
      this.$refs.formAddMerchant.resetValidation()
      this.error_message = {}
		},
    async saveMerchant() {
      if (this.$refs.formAddMerchant.validate()) {
        if (this.dialog_type == 'add') {
          this.btn_loading = true
          await post(`merchant/v1/superuser/create`, {
            data: {
              company_id: this.company_id,
              name: this.form.name,
            }
          }).then((response) => {
            let res = response.data
            if (res.status == 200) {
              this.hideDialog()
              this.$refs.snackbar.open("#4CAF50", `Add Merchant Successfully`);
              this.$emit('action')
            } else {
              this.hideDialog()
              this.$refs.snackbar.open("#e53935", `Add Merchant Failed`);
            }
          }).catch(() => {
            this.error_message = this.$store.state.ContentValidation
            this.btn_loading = false
          })
        } else {
          this.btn_loading = true
          await put(`merchant/v1/superuser/update/${ this.merchant_id }`, {
            data: {
              name: this.form.name,
            }
          }).then((response) => {
            let res = response.data
            if (res.status == 200) {
              this.hideDialog()
              this.$refs.snackbar.open("#4CAF50", `Edit Merchant Successfully`);
              this.$emit('action')
            } else {
              this.hideDialog()
              this.$refs.snackbar.open("#e53935", `Edit Merchant Failed`);
            }
          }).catch(() => {
            this.error_message = this.$store.state.ContentValidation
            this.btn_loading = false
          })
        }
      }
    },
  },
};
</script>
