<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-5'">
    <Snackbar ref="snackbar" />
    <div class="mt-5">
      <template v-if="process.run">
        <v-card loading outlined class="mt-3 elevation-0 rounded-lg">
          <div class="pa-4 text-center">
            <v-icon size="30">mdi-timer-sand</v-icon>
            <div class="pt-2">Content Loading . . .</div>
          </div>
        </v-card>
      </template>
      <template v-else>
        <div class="title black--text mb-2">
          Detail Company ({{ company_detail.name }})
        </div>
        <Breadcrumbs :items="breadcrumbs" />
        <v-card outlined class="pa-4 mt-3 mb-5 rounded-lg" v-if="Object.keys(company_detail).length > 0">
          <v-img cover width="250" height="250" class="rounded-xl" :src="(company_detail.image.url) ? company_detail.image.url : require('@/assets/img/profile_picture.png')"></v-img>
          <div class="pt-3 text-body-1 font-weight-medium">
            Detail Company
          </div>
          <div class="pt-3 body-2">
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Company Name</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.name }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Access Key</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.access_key }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Email Address</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.email }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Phone Number</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.phone_number }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Description</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.description }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Created at</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.created_at | dateFull }}</div>
              </v-col>
            </div>
            <v-col cols="12" class="pb-1 px-0">
              <div class="text-body-1 font-weight-medium">
                Certificate
              </div>
            </v-col>
            <div class="d-flex">
              <v-col cols="6" class="pb-1 px-0">
                <div class="pb-3 grey--text text--darken-1">Signature Image</div>
                <v-img cover width="150" height="150" class="rounded-xl" :src="(company_detail.certificate.signature.image.url) ? company_detail.certificate.signature.image.url : require('@/assets/img/profile_picture.png')"></v-img>
              </v-col>
              <v-col cols="6" class="pb-1 px-0">
                <div class="pb-3 grey--text text--darken-1">Logo Image</div>
                <v-img cover width="150" height="150" class="rounded-xl" :src="(company_detail.certificate.logo.url) ? company_detail.certificate.logo.url : require('@/assets/img/profile_picture.png')"></v-img>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Signature Name</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.certificate.signature.name }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Signature Title</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div class="text-capitalize">{{ company_detail.certificate.signature.title }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Show Competence</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ company_detail.certificate.show_competence ? "Yes" : "No" }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="6" class="pt-2 pl-0">
                <v-card outlined class="pa-4 elevation-0 orange lighten-5 rounded-lg">
                  <div class="grey--text text--darken-1 font-weight-medium">Graduation</div>
                  <div class="pt-3 grey--text text--darken-1">Title</div>
                  <div class="pt-2">{{ company_detail.certificate.graduation.title }}</div>
                  <div class="pt-3 grey--text text--darken-1">Sub Title</div>
                  <div class="pt-2">{{ company_detail.certificate.graduation.sub_title }}</div>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="pt-2 pr-0">
                <v-card outlined class="pa-4 elevation-0 orange lighten-5 rounded-lg">
                  <div class="grey--text text--darken-1 font-weight-medium">Participant</div>
                  <div class="pt-3 grey--text text--darken-1">Title</div>
                  <div class="pt-2">{{ company_detail.certificate.participant.title }}</div>
                  <div class="pt-3 grey--text text--darken-1">Sub Title</div>
                  <div class="pt-2">{{ company_detail.certificate.participant.sub_title }}</div>
                </v-card>
              </v-col>
            </div>
            <v-col cols="12" class="pb-1 px-0">
              <div class="text-body-1 font-weight-medium">
                Settings
              </div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">Enable Notification</div>
              <div class="pl-3">{{ company_detail.setting.enable_notifiicatiion ? "Yes" : "No" }}</div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">Enable Register OTP</div>
              <div class="pl-3">{{ company_detail.setting.enable_register_otp ? "Yes" : "No" }}</div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">Enable Sync Data Member Redeem</div>
              <div class="pl-3">{{ company_detail.setting.enable_sync_data_member_redeem ? "Yes" : "No" }}</div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex">
              <div class="grey--text text--darken-1">One Time Update Profile</div>
              <div class="pl-3">{{ company_detail.setting.one_time_update_profile ? "Yes" : "No" }}</div>
            </v-col>
            <v-col cols="12" class="pt-2 px-0 d-flex" v-if="company_detail.setting.license.enable">
              <div class="grey--text text--darken-1">License Prefix</div>
              <div class="pl-3">{{ company_detail.setting.license.prefix }}</div>
            </v-col>
          </div>
          <div class="mt-3 d-flex flex-wrap justify-space-between">
            <v-btn
              outlined
              large
              color="primary"
              class="rounded-lg text-capitalize"
              :loading="$store.state.process.run"
              @click="showUserCompany"
            >
              {{ show_user_company ? 'Hide' : 'Show' }} User Company
              <v-icon class="ml-1">mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              outlined
              large
              color="primary"
              class="rounded-lg text-capitalize"
              :loading="$store.state.process.run"
              @click="showMerchant"
            >
              {{ show_merchant ? 'Hide' : 'Show' }} Merchant
              <v-icon class="ml-1">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-card>
        <template v-if="show_user_company">
          <UserCompany ref="user_company_table" :company_id="company_detail.id"></UserCompany>
        </template>
        <template v-if="show_merchant">
          <Merchant ref="merchant_table" :company_id="company_detail.id"></Merchant>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
import UserCompany from "./UserCompany.vue";
import Merchant from "./Merchant.vue";

export default {
  components: {
    Pagination,
    Breadcrumbs,
    Snackbar,
    UserCompany,
    Merchant,
  },
  data() {
    return {
      title: "List Users",
      show_user_company: false,
      show_merchant: false,
      breadcrumbs: [
        {
          text: "List Company",
          disabled: false,
          href: "/company",
        },
        {
          text: "Detail Company",
          disabled: true,
          href: "/company/detail-company",
        },
      ],
      company_detail: {},
      process: {
        run: false,
      },
    };
  },
  watch: {
  },
  created() {
    this.getDetailCompany();
  },
  mounted() {},
  methods: {
    showUserCompany() {
      if (!this.show_user_company) {
        this.$store.state.process.run = true
        this.show_merchant = false
        this.show_user_company = true
        setTimeout(() => {
          this.$refs.user_company_table.getUser()
        }, 100)
      } else {
        this.show_user_company = false
      }
    },
    showMerchant() {
      if (!this.show_merchant) {
        this.$store.state.process.run = true
        this.show_user_company = false
        this.show_merchant = true
        setTimeout(() => {
          this.$refs.merchant_table.getMerchant()
        }, 100)
      } else {
        this.show_merchant = false
      }
    },
    async getDetailCompany() {
      this.process.run = true;

      await get(`company/v1/superuser/detail/${this.$route.params.id}`).then(
        (response) => {
          let res = response.data;
          this.company_detail = res.data;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
  },
};
</script>

<style></style>